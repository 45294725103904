
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import CustomContentLoader from "components/ReuseableCompos/CustomContentLoader";
import MolhamButton from "components/ReuseableCompos/MolhamButton";
import SEO from "components/Seo";
import { useModeContext } from "context/ModeContext";
import useHeaderTitleSetter from "customHooks/useHeaderTitleSetter";
import useMobile from "customHooks/useMobile";
import useModal from "customHooks/useModal";
import useRTL from "customHooks/useRTL";
import useWindowSize from "customHooks/useWindowSize";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Link from "next/link";
import { Modal } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { A11y, Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const Ramadan = () => {
    const src = "https://cdn.molhamteam.com/assets/ramadan-cards/";
    const { height, width } = useWindowSize();
    const { t } = useTranslation("common");
    const isMobile = useMobile("md");
    const { theme } = useModeContext();
    const isRTL = useRTL();
    const yy = new Date().getFullYear().toString();
    const title = t("molhamion_in_ramadan") + " " + yy.substr(-2);
    const desc = t("molhamion_in_ramadan_desc") + " " + yy;
    useHeaderTitleSetter({ title });
    const m = useModal();
    const scrollToCampaigns = () => {
        if (typeof window !== "undefined") {
            const element = document.getElementById("campaigns");
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };
    return (<div>
      <SEO title={title}/>
      <Modal show={m.showModal} onHide={m.handleCloseModal} centered contentClassName="bg-trans border-none shadow-none">
        <video src={""} controls autoPlay width="100%" height="100%" style={{ objectFit: "cover" }}/>
      </Modal>
      <div className="position-relative user-select-none">
        <div className="position-absolute top-50 start-50 translate-middle zindex-2 w-100">
          <div className="centered flex-column w-100">
            <div className="centered bg-white p-2 rounded-circle" 
    // style={{ width: 60, height: 60 }}
    onClick={() => {
            // m.handleShowModal();
        }}>
              {/* <i className="fe-play fs-xxl me-1 text-primary" /> */}
              <SvgComponent hw="44px" uri={theme === "dark"
            ? "/assets/rmdiconwh.svg"
            : "/assets/rmdicon.svg"}/>
            </div>

            <h1 style={{
            fontSize: "xxx-large"
        }} className=" mt-4 mb-0 w-100 text-center text-white-always">
              {title}
            </h1>
            <h5 className="mb-5 mt-4 w-100 text-center text-white-always">
              {desc}
            </h5>

            <div className="centered flex-column w-100">
              <StyledMB1 labelClassName="txtclr fw-bold" id="ramadan-zakat-btn" href="/campaigns/716" label={t("last_nights_zakat")} style={{ minWidth: "220px" }} color="#ffc422" endIcon="fe-arrow-right txtclr"/>

              <StyledMB1 labelClassName="txtclr fw-bold" id="ramadan-btn" label={t("browse_all_campaigns")} style={{ minWidth: "220px" }} startIcon="fe-heart" className="mt-3" color="#65caee" onClick={scrollToCampaigns}/>
              {/* <MolhamButton
          id="ramadan-btn"
          label={t("browse_all_campaigns")}
          style={{ minWidth: "200px" }}
          startIcon="fe-heart"
          onClick={scrollToCampaigns}
        /> */}

              <StyledMB1 labelClassName="txtclr fw-bold" id="ramadan-zakat-btn" href="/campaigns/686" label={t("ramadan24_cards_1_title")} className="mt-3" style={{ minWidth: "220px" }} color="#fa805c" endIcon="fe-arrow-right txtclr"/>
            </div>
          </div>
        </div>
        <Image unoptimized alt="ramadanPromo" src={"/assets/bg25.webp"} width={width || 1920} height={height || 1080} style={{
            height: "85vh",
            maxHeight: "85vh",
            objectFit: "cover",
            filter: theme === "dark" ? "brightness(0.65)" : "brightness(0.9)"
        }}/>
      </div>
      <div className="centered flex-column w-100 my-5">
        <Image unoptimized alt="logo" loading="eager" src={theme === "dark"
            ? "/assets/molhamiongs.png"
            : "/assets/molhamion.png"} width={isMobile ? 200 : 250} height={isMobile ? 200 : 250}/>
        <div style={{
            width: 50,
            background: "#fa805c",
            height: 2
        }} className="my-3"/>
        <h4 className="text-white text-center fw-normal lh17 " style={{ maxWidth: isMobile ? "85vw" : "60vw" }}>
          {t("ramadan_is_your_annual")}
        </h4>
        <div className="position-relative">
          <div id={"campaigns"} className="position-absolute" style={{ top: -100 }}/>
        </div>
      </div>
      <StyledSwiper key={isRTL.toString()} modules={[Navigation, A11y, Autoplay]} autoplay={{
            delay: 7500
        }} speed={600} slidesPerView={isMobile ? 1.5 : 4} dir={isRTL ? "rtl" : "ltr"} loop theme={theme} spaceBetween={12} centeredSlides={isMobile === true} initialSlide={2} 
    // freeMode={{
    //   enabled: true,
    //   sticky: false,
    //   momentumBounce: false,
    //   minimumVelocity: 0,
    // }}
    navigation>
        {campaigns.map((item, index) => {
            if (item === null)
                return;
            return (<StyledSwiperSlide key={index} className="overflow-hidden position-relative user-select-none">
              <div className="position-absolute top-50 start-50 translate-middle zindex-2 w-100 h-100">
                <div className="d-flex align-items-center justify-content-end flex-column h-100">
                  <Link href={`/campaigns/${item}`} style={{ maxWidth: "95%" }} className={`text-center text-white-always cursor text-decoration-0 mb-2 fw-bold ${!isMobile ? "h4" : "fs-lg"}`}>
                    {t(`rmd25_${item}_title`)}
                  </Link>
                  <Link href={`/campaigns/${item}`} className={`text-center text-white-always cursor text-decoration-0 mb-2  ${!isMobile ? "fs-md" : "fs-sm"}`} style={{ maxWidth: "75%" }}>
                    {t(`rmd25_${item}_desc`)}
                  </Link>
                  <StyledMB1 customHeight={"36px"} id={`ramadan-donate-for-campaign+${item}`} labelClassName="txtclr fw-bold fs-xl" label={t("donate_for_campaign")} className=" text-white-always px-3 py-0 mt-1 centered" hrefClassName="py-0 centered m-3 mt-0" variant="outline-light" href={`/campaigns/${item}`} color="#fa805c"/>
                </div>
              </div>
              <Image unoptimized loading="eager" className={"image"} src={`${src}${item}.jpg`} 
            //cehck
            alt={"intro"} width={width || 1920} height={height || 1080} style={{
                    objectFit: "cover",
                    filter: theme === "dark" ? "brightness(0.5)" : "brightness(0.65)",
                    transition: "transform 0.3s ease"
                }}/>
            </StyledSwiperSlide>);
        })}
      </StyledSwiper>
      <div className="w-100 centered flex-column my-5">
        <h5 className="mb-4 text-center" style={{ maxWidth: "85vw" }}>
          {t("no_online_payment")}
        </h5>
        <StyledMB className="" id="ramadan-all-payments" variant="outline-primary" labelClassName="txtclr fw-bold" href="/help/faqs/payment" label={t("other_payment_methods")}/>
      </div>
    </div>);
};
export default Ramadan;
const StyledMB = styled(MolhamButton) `
  .txtclr {
    transition: all 0.3s ease;
    color: var(--primary2) !important;
  }
  &:hover {
    .txtclr {
      color: var(--white) !important;
    }
  }
`;
const StyledMB1 = styled(MolhamButton)<{
    color?: string;
}> `
  background: ${({ color }) => color} !important;
  border-color: ${({ color }) => color} !important;
  .txtclr {
    color: #fff !important;
  }
  &:hover {
    border-color: ${({ color }) => color} !important;

    background: ${({ color }) => color} !important;

    .txtclr {
      color: #fff !important;
    }
  }
`;
const StyledSwiperSlide = styled(SwiperSlide) `
  &:hover {
    .image {
      //transform scale only on desktop
      @media (min-width: 768px) {
        transform: scale(1.1);
      }
    }
  }
`;
const StyledSwiper = styled(Swiper) `
  .swiper-pagination {
    width: 100%;
    text-align: center !important;
    bottom: 0 !important;
    @media (max-width: 768px) {
      bottom: 12px !important;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    /* top: 148px !important; */
    background: #fff !important;
    border-radius: 25% !important;
    width: 50px !important;
    height: 50px !important;
    @media (max-width: 767.98px) {
      display: none !important;
    }
  }
  .swiper-button-prev::after {
    margin-inline-end: 4px !important;
    z-index: 3 !important;
  }

  .swiper-button-next {
    margin-inline-start: 4px !important;
    z-index: 3 !important;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    color: var(--primary2) !important;

    font-size: 25px !important;
  }

  .swiper-button-disabled {
    display: none !important;
  }
`;
const campaigns = [
    676, 678, 679, 680, 681, 683, 684, 685, 686, 687, 688, 689, 690,
];
const StyledQDB = styled(MolhamButton) `
  color: var(--primary2) !important;
  background-color: #fff;
  &:hover {
    color: #fff !important;
    background-color: var(--primary2) !important;
    border: 1px solid var(--primary2) !important;
  }
  min-width: 200px;
`;
const SvgComponent = ({ hw, uri }: {
    hw: string;
    uri: string;
}) => (<ReactSVG loading={() => (<CustomContentLoader viewBox={`0 0 ${hw} ${hw}`} width={hw} height={hw}>
        <rect x="0" y="0" rx="8" ry="8" width={hw} height={hw}></rect>
      </CustomContentLoader>)} src={uri} beforeInjection={(svg) => {
        svg.setAttribute("width", hw);
        svg.setAttribute("height", hw);
    }}/>);

    async function __Next_Translate__getStaticProps__195ba98c417__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/ramadan',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195ba98c417__ as getStaticProps }
  