import { THomeWidget } from "apiTypes";
import { useModeContext } from "context/ModeContext";
import useMobile from "customHooks/useMobile";
import { nextCheckoutItemSourceManager } from "customHooks/useNextCheckoutItemSource";
import useRTL from "customHooks/useRTL";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { A11y, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import NewDonateCardHome from "./NewDonateCardHome";
type Props = { group: THomeWidget };

function NewHomeCardsSlider({ group }: Props) {
  const { theme } = useModeContext();
  const { t } = useTranslation("common");
  const isRTL = useRTL();
  const { push } = useRouter();
  const isMobile = useMobile("lg");
  const onLinkClick = () => {
    nextCheckoutItemSourceManager.setNextCheckoutItemSource(
      group.type === "target_cards"
        ? nextCheckoutItemSourceManager.options.target_cards_widget
        : group.type === "blog_posts_cards"
        ? nextCheckoutItemSourceManager.options.blog_post
        : undefined
    );
  };
  const onCardClick = (id) => {
    onLinkClick();
    push(`${id}`);
  };
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const [canNext, setCanNext] = useState(false);
  const [canPrev, setCanPrev] = useState(false);
  const goNext = () => {
    if (swiper) {
      swiper.slideNext();
      setCanNext(swiper?.snapGrid.length - 1 > swiper?.snapIndex);
      setCanPrev(swiper?.snapIndex > 0);
    }
  };

  const goPrev = () => {
    if (swiper) {
      swiper.slidePrev();
      setCanPrev(swiper?.snapIndex > 0);
      setCanNext(swiper?.snapGrid.length - 1 > swiper?.snapIndex);
    }
  };
  return (
    <Container className="container">
      <div className="d-flex align-items-center justify-content-between mb-3 topdiv">
        <div
          style={{
            color: "var(--dark-gray, #4A4B65)",
            textAlign: "center",
            fontSize: isMobile ? 24 : "28px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: isMobile ? "40px" : "48px",
          }}
        >
          {group?.title}
        </div>

        {swiper && (
          <StyledControls className="d-flex align-items-center gap-2">
            {group.more_path?.web && (
              <StyledMore
                href={group.more_path?.web}
                onClick={() => {
                  onLinkClick();
                }}
              >
                {t("more")}
              </StyledMore>
            )}
            {!isMobile && (
              <StyledNextPrevContainer onClick={() => canPrev && goPrev()}>
                <StyledNextPrev
                  className={"fe-chevron-left fs-lg "}
                ></StyledNextPrev>
              </StyledNextPrevContainer>
            )}
            {!isMobile && (
              <StyledNextPrevContainer onClick={() => canNext && goNext()}>
                <StyledNextPrev
                  className={"fe-chevron-right fs-lg "}
                ></StyledNextPrev>
              </StyledNextPrevContainer>
            )}
          </StyledControls>
        )}
      </div>
      <div className="swipercont">
        <StyledSwiper
          key={isRTL.toString()}
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={20}
          slidesPerView="auto"
          navigation
          dir={isRTL ? "rtl" : "ltr"}
          theme={theme}
          onSwiper={(swiper) => {
            setSwiper(swiper);
            setCanNext(swiper?.snapGrid.length - 1 > swiper?.snapIndex);
            setCanPrev(swiper?.snapIndex > 0);
          }}
        >
          {group?.[
            group.type === "blog_posts_cards" ? "blog_posts" : "targets"
          ].map((item, idx) => {
            return (
              <SwiperSlide key={idx}>
                <NewDonateCardHome
                  data={item}
                  onCardClick={onCardClick}
                  noBtn
                  noDoubleSlash
                />
              </SwiperSlide>
            );
          })}
        </StyledSwiper>
      </div>
    </Container>
  );
}

export default NewHomeCardsSlider;
const StyledSwiper = styled(Swiper)`
  overflow: unset;
  .swiper-slide {
    width: fit-content !important;
  }
  .swiper-pagination {
    width: 100%;
    text-align: center !important;
    bottom: 0 !important;
    @media (max-width: 768px) {
      bottom: 12px !important;
    }
  }
  .swiper-wrapper {
    padding-bottom: 48px !important;
    @media (max-width: 768px) {
      padding-bottom: 48px !important;
    }
  }

  .swiper-pagination-bullet {
    background: ${({ theme }) =>
      theme === "light" ? "var(--primary2)" : "#fff"} !important;
    margin-inline: 8px !important;
    opacity: 1 !important;
    @media (max-width: 767.98px) {
      background: #fff !important;
    }
  }

  .swiper-pagination-bullet-active {
    background: transparent !important;
    border: 1px solid
      ${({ theme }) => (theme === "light" ? "var(--primary2)" : "#fff")} !important;
    transform: scale(2) !important;
    @media (max-width: 767.98px) {
      background: transparent !important;
      border: 1px solid #fff !important;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
  .swiper-button-prev::after {
    margin-inline-end: 4px !important;
  }

  .swiper-button-next {
    margin-inline-start: 4px !important;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    color: var(--primary2) !important;

    font-size: 25px !important;
  }

  .swiper-button-disabled {
    display: none !important;
  }
`;
const Container = styled.div`
  .btn {
    color: var(--primary2) !important;
  }
  .btn:hover {
    color: #fff !important;
  }
`;
const StyledControls = styled.div``;
const StyledNextPrevContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 500px;
  background: rgba(0, 0, 0, 0.3);
  padding: 8px;
  backdrop-filter: blur(20px);
  cursor: pointer;
`;

const StyledNextPrev = styled.i`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 50px;
  color: rgba(255, 255, 255, 0.85);
  transition: color 0.3s ease-in-out;
  &:hover {
    color: #fff;
  }

  cursor: pointer;
`;

const StyledMore = styled(Link)`
  color: var(--dark-gray) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  background: rgba(0, 0, 0, 0.3) !important;
  color: rgba(255, 255, 255, 0.85) !important;
  transition: color 0.3s ease-in-out !important;
  &:hover {
    color: #fff !important;
  }
  text-decoration: none !important;
  padding: 6px 10px !important;
  border-radius: 12px !important;

  cursor: pointer !important;
`;
