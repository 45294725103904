import LogoCreator from "components/NavBar/LogoCreator";
import MolhamButton from "components/ReuseableCompos/MolhamButton";
import { useModeContext } from "context/ModeContext";
import useMobile from "customHooks/useMobile";
import { m } from "framer-motion";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { useEffect, useState } from "react";
import styled from "styled-components";

type Props = {};

function HomeIntroAbout({}: Props) {
  const { t } = useTranslation();
  const { theme } = useModeContext();

  const list = [
    1,

    0,

    3,
  ];
  const degs = [
    -2.5,

    4, 10,
  ];
  const [imageRotation, setImageRotation] = useState([0, 1, 2]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageRotation((prevRotation) => {
        const newRotation = [...prevRotation];
        newRotation.push(newRotation.shift());
        return newRotation;
      });
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const isMobile = useMobile("lg");
  return (
    <div
      className="container w-100 d-flex align-items-center justify-content-between"
      style={{
        paddingTop: isMobile ? 60 : "120px",
        paddingBottom: isMobile ? 60 : "120px",
        overflow: "hidden",
      }}
    >
      <div className="d-flex flex-column" id="sibling" style={{ gap: 32 }}>
        <LogoCreator hwd={"64px"} hwm={"64px"} />

        <StyledIntro
          style={{
            width: 640,
            fontSize: 32,
            // fontWeight: 700,
            marginBottom: 0,
            lineHeight: "44px",
            maxWidth: isMobile ? "90vw" : "unset",
          }}
          dangerouslySetInnerHTML={{
            __html: t("hero_about_intro"),
          }}
        ></StyledIntro>
        <MolhamButton
          id="learn-more-home-btn"
          hrefClassName="align-items-start"
          href={"/about"}
          variant="primary"
          endIcon="fe-chevron-right"
          label={t("learn_more")}
          hrefNoFlex
        />
      </div>
      <div
        className="position-relative w-100 d-none d-lg-flex"
        style={{
          minHeight: 400,
          marginInlineEnd: 24,
        }}
      >
        <m.div
          //@ts-ignore
          key={imageRotation}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 1,
            ease: "linear",
          }}
        >
          <Img deg={degs[0]} num={list[imageRotation[0]]} />
        </m.div>
        <m.div
          //@ts-ignore
          key={imageRotation}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 1,
            ease: "linear",
          }}
        >
          <Img deg={degs[1]} num={list[imageRotation[1]]} />
        </m.div>
        <m.div
          //@ts-ignore
          key={imageRotation}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 1,
            ease: "linear",
          }}
        >
          <Img deg={degs[2]} num={list[imageRotation[2]]} />
        </m.div>
      </div>
    </div>
  );
}

export default HomeIntroAbout;

const Img = ({
  num,
  deg,
  phone,
}: {
  num: number;
  deg: number;
  phone?: boolean;
}) => {
  const phoneStyle = {
    right: 0,
    left: 0,
    margin: "0 auto",
    transform: `rotate(${deg}deg)`,
  };
  const webStyle = {
    insetInlineEnd: 0,
    transform: `rotate(${deg}deg)`,
  };
  const isMobile = useMobile("lg");
  return (
    <div
      className="position-absolute z-index-1"
      style={phone ? phoneStyle : webStyle}
    >
      <div className="w-100 h-100 position-relative">
        {deg == 10 && (
          <div
            style={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 99,
            }}
          >
            <MolhamLogo color="rgba(255, 255, 255, 0.70)" />
          </div>
        )}
        <Image
          src={`https://cdn.molhamteam.com/assets/homepage-sliders/intro${num}.jpg`}
          unoptimized
          alt={`intro-${num}`}
          className=" rounded"
          width={600}
          height={600}
          style={{
            objectFit: "cover",
            height: isMobile ? 360 : 440,
            width: isMobile ? 320 : 360,
          }}
        />
      </div>
    </div>
  );
};

const MolhamLogo = ({ color }: { color: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={57} height={63} fill="none">
      <path
        fill={color}
        d="M27.127 35.077c.145-.825.764-1.353 1.465-1.76.222-.068.444-.135.75-.081.305.053.592.21.777.35.455.398.79.882.986 1.555.13.448.16.878.19 1.308.046.327.01.533-.146.824-.193.498-.487.977-.866 1.335-.498.444-1.349.506-1.905.09-.287-.157-.454-.4-.622-.641-.418-.605-.734-1.193-.709-1.932.073-.412.127-.721.08-1.048ZM4.485 39.054s1.508-11.53 13.617-11.732c.018-.103-.505 10.006-13.617 11.732Z"
      />
      <path
        fill={color}
        d="M4.485 39.054s1.166-11.377 13.617-11.732c.018-.103-5.8 9.072-13.617 11.732ZM.15 25.538s6.991-9.287 17.584-3.275c0 0-5.468 8.387-17.584 3.275Z"
      />
      <path
        fill={color}
        d="M.15 25.538s6.668-9.238 17.584-3.275c0 0-9.577 4.9-17.584 3.275ZM8.61 12.685s9.814-3.264 14.6 6.506c0 0-8.167 3.448-14.6-6.506Z"
      />
      <path
        fill={color}
        d="M8.61 12.685s9.544-3.524 14.6 6.506c0 0-9.542-1.258-14.6-6.506Z"
      />
      <path
        fill={color}
        d="M20.764.908s9.437 6.02 4.67 16.975c0 0-8.534-4.586-4.67-16.975Z"
      />
      <path
        fill={color}
        d="M20.764.908s9.491 5.711 4.67 16.975c0 0-5.56-8.95-4.67-16.975ZM40.945 5.53S39.9 16.82 28.125 17.506c.102.018.247-9.732 12.82-11.978Z"
      />
      <path
        fill={color}
        d="M40.945 5.53s-.704 11.138-12.82 11.977c.102.018 5.392-9.144 12.82-11.978ZM46.771 13.57s-3.286 10.896-14.947 9.159c0 0 2.097-9.513 14.947-9.16Z"
      />
      <path
        fill={color}
        d="M46.771 13.57s-2.944 10.744-14.947 9.159c0 0 7.06-7.894 14.947-9.16Z"
      />
      <path
        fill={color}
        d="M56.691 23.394s-8.282 7.678-17.464.215c0 0 6.628-7.226 17.464-.215Z"
      />
      <path
        fill={color}
        d="M56.691 23.394s-7.875 7.75-17.464.215c0 0 10.017-3.228 17.464-.215ZM56.867 31.926S50.1 36.365 44.51 29.96c0 0 5.49-4.345 12.355 1.966Z"
      />
      <path
        fill={color}
        d="M56.867 31.926s-6.581 4.578-12.356-1.966c0 0 7.443-1.132 12.355 1.966ZM38.049 25.526s.27 6.211-6.137 7.42c0 0-.637-5.32 6.137-7.42Z"
      />
      <path
        fill={color}
        d="M38.049 25.526s.408 6.023-6.137 7.42c0 0 2.305-5.332 6.137-7.42ZM28.915 18.39s2.84 5.92-2.883 9.8c0 0-2.819-4.854 2.883-9.8Z"
      />
      <path
        fill={color}
        d="M28.915 18.39s2.876 5.714-2.883 9.8c0 0 .036-6.158 2.883-9.8ZM18.567 25.279s6.468.822 6.509 7.736c0 0-5.625-.248-6.51-7.736Z"
      />
      <path
        fill={color}
        d="M18.567 25.279s6.385.7 6.509 7.736c0 0-5.08-3.34-6.51-7.736ZM42.94 27.558c-3.84 2.723-16.645 13.429-11.618 34.93.047.327.574.313.527-.014.567-5 2.915-20.1 11.444-34.535.138-.189-.03-.43-.233-.466.102.018 0 0-.12.085Z"
      />
      <path
        fill={color}
        d="M16.222 35.598c5.117 3.133 14.986 11.462 9.184 25.315-.175.394.4.708.574.314 4.116-6.075 10.547-19.924-9.427-26.315l-.102-.018c-.305-.053-.516.547-.229.704Z"
      />
    </svg>
  );
};

const StyledIntro = styled.div`
  p {
    margin-bottom: 0;
  }
`;
