import SvgCreator from "components/ReuseableCompos/SvgCreator";
import useMobile from "customHooks/useMobile";
import useUtilities from "customHooks/useUtilities";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";

type Props = {};

function HomeHeroes({}: Props) {
  const { data: utils } = useUtilities();
  const { t } = useTranslation();
  const isMobile = useMobile("lg");
  const getIcon = (sec) => {
    return (
      utils?.donation_sections.find((ds) => ds.key === sec)?.icon ?? {
        uri: "",
        color: "",
      }
    );
  };
  const heroImgs = [
    {
      src: "https://cdn.molhamteam.com/assets/home/hero/cases.jpeg",
      key: "cases",
      amount: "26416",
    },
    {
      src: "https://cdn.molhamteam.com/assets/home/hero/campaigns.jpeg",
      key: "campaigns",
      amount: "658",
    },
    {
      src: "https://cdn.molhamteam.com/assets/home/hero/sponsorships.jpeg",
      key: "sponsorships",
      amount: "8172",
    },
  ];
  const heroImgs2 = [
    {
      src: "https://cdn.molhamteam.com/assets/home/hero/shelter.jpeg",
      key: "shelter",
      amount: "11",
    },
    {
      src: "https://cdn.molhamteam.com/assets/home/hero/education.jpeg",
      key: "students",
      amount: "3297",
    },
    {
      src: "https://cdn.molhamteam.com/assets/home/hero/zakat.jpeg",
      key: "zakat",
      amount: "$5.5M+",
    },
  ];
  const HeroImg = ({ img }: any) => {
    return (
      <div key={img.key} className="home-hero-section-img">
        <Image
          src={img.src}
          alt={img.key}
          width={600}
          height={600}
          style={{
            objectFit: "cover",
            height: 400,
            width: "100%",
            borderRadius: 24,
            filter: "brightness(0.6)",
          }}
        />
        <div
          className="position-absolute  w-100"
          style={{
            height: 400,
            padding: 40,
            top: 0,
            left: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <SvgCreator
            color="#fff"
            uri={getIcon(img.key).uri}
            hwm="48"
            hwd="48"
            p="p-0"
          />
          <div>
            <div
              className="text-white-always"
              style={{
                fontSize: 20,
                fontWeight: 400,
                lineHeight: "28px",
              }}
            >
              {t(img.key)}
            </div>
            <div
              className="text-white-always"
              style={{
                fontSize: 80,
                fontWeight: 700,
                lineHeight: "88px",
              }}
            >
              {img.amount}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="d-flex flex-column  w-100 container"
      style={{
        marginBottom: 48,
      }}
    >
      <div
        style={{
          color: "var(--dark-gray, #4A4B65)",
          fontSize: isMobile ? "24px" : "28px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "48px",
        }}
        className="mb-4"
      >
        {t("we_help_those")}
      </div>
      <div className="d-flex flex-column flex-lg-row w-100 gap-3 mb-3 ">
        {heroImgs.map((img, index) => (
          <HeroImg key={index} img={img} />
        ))}
      </div>
      <div className="d-flex flex-column flex-lg-row w-100 gap-3  ">
        {heroImgs2.map((img, index) => (
          <HeroImg key={index} img={img} />
        ))}
      </div>
    </div>
  );
}

export default HomeHeroes;
