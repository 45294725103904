import { MolhamListTargetable, TBlog } from "apiTypes";
import StyledDate from "components/ReuseableCompos/StyledDate";
import { tragetableFallbackImg } from "config";
import { usePreferenceContext } from "context/PreferencesContext";
import useMobile from "customHooks/useMobile";
import { nextCheckoutItemSourceManager } from "customHooks/useNextCheckoutItemSource";
import dayjs from "dayjs";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { memo } from "react";
import HomeDonateProgressBar from "../ReuseableCompos/SharedDonatePages/HomeDonateProgressBar";
type Props = {
  data?: TBlog | MolhamListTargetable;
  handleShowShareModal?: () => void;
  onCardClick?: (id: string) => void;
  noBtn?: boolean;
  noDoubleSlash?: boolean;
};

const DonateCardHome = ({
  data,
  handleShowShareModal = () => {},
  onCardClick,
  noBtn,
  noDoubleSlash,
}: Props) => {
  const router = useRouter();
  const isTargetable = "path" in data;
  const { curr } = usePreferenceContext();
  const { preview_image, id } = data;
  const path = isTargetable ? data?.path : "blog/" + id;

  const onLinkClick = () => {
    nextCheckoutItemSourceManager.setNextCheckoutItemSource(
      isTargetable
        ? nextCheckoutItemSourceManager.options.target_cards_widget
        : nextCheckoutItemSourceManager.options.blog_post
    );
  };
  const isSmall = useMobile("md");
  const href = `${router.asPath.split("?")[0]}${
    noDoubleSlash ? "" : "/"
  }${path}`;
  return (
    <Link
      href={href}
      className="text-decoration-none w-fit-content d-flex flex-column"
      style={{ width: isSmall ? 300 : 380 }}
    >
      <div
        className="donate-card rounded"
        style={{
          height: 224,
          width: "100%",
        }}
      >
        <Image
          height={512}
          unoptimized
          width={512}
          className="object-fit-cover"
          src={
            isSmall
              ? preview_image?.thumbnails?.["3:4"] ??
                preview_image?.thumbnails?.["1:1"]
              : preview_image?.thumbnails?.["1:1"] || tragetableFallbackImg
          }
          alt={data.contents?.title}
        />
      </div>
      <div
        style={{
          overflow: "hidden",
          color: "var(--dark-gray, #4A4B65)",
          textOverflow: "ellipsis",
          fontSize: 20,
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "28px",
          marginTop: 24,
          marginBottom: 0,
          display: "-webkit-box",
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical",
        }}
      >
        {data.contents?.title}
      </div>
      {isTargetable ? (
        <>
          <div
            style={{
              overflow: "hidden",
              color: "var(--gray, #4A4B65)",
              textOverflow: "ellipsis",
              fontSize: 14,
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "28px",
              marginTop: 0,
              marginBottom: 16,
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
            }}
          >
            {data.contents?.description ? data.contents?.description : `‎`}
          </div>

          <HomeDonateProgressBar
            progressPrecentage={data?.funding_progress_bar?.percentage}
            received={
              data?.funding_progress_bar?.blocks[0]?.amount?.[curr?.code]
            }
            left={data?.funding_progress_bar?.blocks[1]?.amount?.[curr?.code]}
            blocks={data?.funding_progress_bar?.blocks}
          />
        </>
      ) : (
        <StyledDate
          className="fs-md text-muted mt-2"
          date={dayjs(data?.published_at).format("DD MMMM YYYY")}
        />
      )}
    </Link>
  );
};

export default memo(DonateCardHome);
