type Props = {
  date: string;
  className?: string;
};
const StyledDate = ({ date, className }: Props) => {
  return (
    <span className={`v-centered ${className ? className : ""}`}>{date}</span>
  );
};

export default StyledDate;
